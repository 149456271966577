import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageLayout, RESET_PARAMS, Row, selectQueryParams, Table, Title, useTranslation } from '@gk-devteam/apmc-core-web'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'

import { selectLoadingYasueContacts, selectYasueContactsCount, selectYasueContactsData } from '../../../selectors'
import { FETCH_YASUE_CONTACTS, RESET_YASUE_CONTACTS } from '../../../types'
import ContactModal from './ContactModal'

const ContactsPage = ({ location }) => {
  const { t } = useTranslation(['common', 'yasue'])
  const dispatch = useDispatch()
  const [activeMessage, setActiveMessage] = useState(null)
  const [visible, setVisible] = useState(false)
  const query = useSelector(selectQueryParams)
  const prevQuery = useRef(null)

  const fetchData = useCallback(
    () => {
      dispatch({ type: FETCH_YASUE_CONTACTS, query })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_YASUE_CONTACTS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const breadcrumbs = [
    {
      label: 'navigation:yasue.contacts',
      link: '/yasue/contacts'
    }
  ]

  const _showMessage = (_, row, e) => {
    setActiveMessage(row)
    setVisible(true)
  }
  const _closeModal = () => {
    setActiveMessage(null)
    setVisible(false)
  }

  const columns = [
    {
      title: t('contracts:contractor_name'),
      dataIndex: 'contractor_name',
      key: 'contractor_name',
      cellTitle: true
    },
    {
      title: t('yasue:construction.work_number'),
      dataIndex: 'work_number',
      key: 'work_number'
    },
    // {
    //   title: t('yasue:contacts.message'),
    //   dataIndex: 'message',
    //   key: 'message'
    // },
    {
      title: t('yasue:contacts.sent'),
      dataIndex: 'sent',
      key: 'sent'
    }
  ]

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
    >
      <ContactModal
        visible={visible}
        close={_closeModal}
        contact={activeMessage}
      />
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:yasue.contacts"/>
      </Row>
      <Table
        fullHeight
        columns={columns}
        rowKey="id"
        loadingSelector={selectLoadingYasueContacts}
        resultsCountSelector={selectYasueContactsCount}
        dataSelector={selectYasueContactsData}
        onRowClick={_showMessage}
        rightStickyColumnCount={0}
      />
    </PageLayout>
  )
}

export default ContactsPage

ContactsPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
ContactsPage.defaultProps = {}
