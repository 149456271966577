/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const propertyUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('id')),
    name: yup.string().max(255).required().label(i18n.t('properties:property_name')),
    property_manage_id: yup.string().nullable().label(i18n.t('properties:property_manage_id')),
    room_count: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .label(i18n.t('properties:room_count')),
    postcode: yup.string().matches(/^\d{7}$/).required().label(i18n.t('properties:postcode')),
    prefecture: yup.string().max(255).required().label(i18n.t('properties:prefecture')),
    city: yup.string().max(255).required().label(i18n.t('properties:city')),
    address: yup.string().max(255).required().label(i18n.t('common:address')),
    bank_account: yup.string().nullable().max(255).label(i18n.t('properties:bank_account')),
    rooms: yup.array().of(
      yup.object().shape({
        room_number: yup.string().required().label(i18n.t('properties:room_number'))
      })
    ).label(i18n.t('properties:rooms_settings')),
    property_owner_enabled: yup.bool().required().label(i18n.t('properties:property_owner_info')),
    property_owner: yup.object().shape({
      owner_name: yup
        .string()
        .test(
          'conditional_required',
          i18n.t('required', { label: '${label}' }),
          function (value) {
            const { property_owner_enabled } = this.from[1].value
            if (!value && (property_owner_enabled)) return false
            return true
          })
        .label(i18n.t('properties:owner_name')),
      owner_furigana: yup
        .string()
        .test(
          'conditional_required',
          i18n.t('required', { label: '${label}' }),
          function (value) {
            const { property_owner_enabled } = this.from[1].value
            if (!value && (property_owner_enabled)) return false
            return true
          })
        .label(i18n.t('common:furigana')),
      owner_postcode: yup
        .string()
        .nullable()
        .matches(/^\d{7}$/)
        .test(
          'conditional_required',
          i18n.t('required', { label: '${label}' }),
          function (value) {
            const { property_owner_enabled } = this.from[1].value
            if (!value && (property_owner_enabled)) return false
            return true
          })
        .label(i18n.t('properties:postcode')),
      owner_address: yup
        .string()
        .test(
          'conditional_required',
          i18n.t('required', { label: '${label}' }),
          function (value) {
            const { property_owner_enabled } = this.from[1].value
            if (!value && (property_owner_enabled)) return false
            return true
          })
        .label(i18n.t('common:address')),
      owner_phone: yup
        .string()
        .test(
          'conditional_required',
          i18n.t('required', { label: '${label}' }),
          function (value) {
            const { property_owner_enabled } = this.from[1].value
            if (!value && (property_owner_enabled)) return false
            return true
          })
        .label(i18n.t('common:phone')),
      owner_display_phone: yup.bool().label(i18n.t('properties:display_phone')),
      owner_contact_mail_address: yup.string().email().nullable().label(i18n.t('properties:contact_mail_address')),
      owner_url: yup.string().nullable().label(i18n.t('common:homepage')),
      owner_business_hour: yup.string().nullable().label(i18n.t('properties:contact_hours')),
      delete_property_owner_image: yup.bool().label(i18n.t('common:delete_file_flag'))
    }).label('properties:property_owner_info'),
    chairman_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('properties:documents.chairman')),
    inspector_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('properties:documents.inspector')),
    memo: yup.string().nullable().max(5000).label(i18n.t('common:memo')),
    delete_file: yup.bool().required().label(i18n.t('common:delete_file_flag'))
  })
}
