import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const adminProfileUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    name: yup.string().max(255).nullable().label(i18n.t('common:name')),
    furigana: yup.string().max(255).nullable().label(i18n.t('common:furigana')),
    postcode: yup.string().matches(/^\d{7}$/).label(i18n.t('properties:postcode')),
    address: yup.string().max(255).nullable().label(i18n.t('common:address')),
    phone: yup.string().max(255).nullable().label(i18n.t('common:phone')),
    contact_mail_address: yup.string().nullable().email().label(i18n.t('properties:contact_mail_address')),
    url: yup.string().max(255).nullable().label(i18n.t('common:homepage')),
    display_phone: yup.bool().nullable().nullable().label(i18n.t('properties:display_phone')),
    business_hour: yup.string().nullable().max(255).label(i18n.t('properties:contact_hours')),
    file: yup.string().nullable().isBlob().label(i18n.t('common:file'))
  })
}
